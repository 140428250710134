import React from 'react';

const TwitterIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.173 9.17641C26.2015 8.54104 26.971 7.54059 27.338 6.36184C26.3715 6.9544 25.3141 7.37181 24.2115 7.59603C22.6828 5.92498 20.2607 5.51833 18.2986 6.60329C16.3365 7.68825 15.3202 9.99623 15.8176 12.2376C11.8588 12.0322 8.17053 10.0999 5.67052 6.92143C4.36582 9.24686 5.03255 12.2195 7.19419 13.7148C6.41252 13.6889 5.64818 13.4702 4.96491 13.077C4.96491 13.0983 4.96491 13.1197 4.96491 13.141C4.96536 15.5633 6.6175 17.6499 8.91519 18.1299C8.19015 18.3338 7.42963 18.3638 6.69165 18.2177C7.33783 20.2892 9.18545 21.7084 11.2913 21.7507C9.54718 23.1652 7.39322 23.9324 5.17602 23.9286C4.78302 23.9292 4.39034 23.9058 4 23.8587C6.25152 25.3537 8.87202 26.1473 11.5483 26.1445C15.2717 26.1709 18.85 24.6541 21.4828 21.9333C24.1156 19.2125 25.5832 15.5148 25.5573 11.6673C25.5573 11.4468 25.5523 11.2275 25.5424 11.0093C26.5067 10.2892 27.3389 9.39711 28 8.37496C27.1017 8.78643 26.1487 9.05658 25.173 9.17641Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TwitterIcon;
