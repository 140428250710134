import React from 'react';

const LogoutIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6263 10.2466C11.4996 10.2466 11.3729 10.2 11.2729 10.1C11.0796 9.90663 11.0796 9.58663 11.2729 9.39329L12.6263 8.03996L11.2729 6.68663C11.0796 6.49329 11.0796 6.17329 11.2729 5.97996C11.4663 5.78663 11.7863 5.78663 11.9796 5.97996L13.6863 7.68663C13.8796 7.87996 13.8796 8.19996 13.6863 8.39329L11.9796 10.1C11.8796 10.2 11.7529 10.2466 11.6263 10.2466Z"
        fill="currentColor"
      />
      <path
        d="M13.2868 8.54004H6.50684C6.2335 8.54004 6.00684 8.31337 6.00684 8.04004C6.00684 7.76671 6.2335 7.54004 6.50684 7.54004H13.2868C13.5602 7.54004 13.7868 7.76671 13.7868 8.04004C13.7868 8.31337 13.5602 8.54004 13.2868 8.54004Z"
        fill="currentColor"
      />
      <path
        d="M7.84017 13.8337C4.40684 13.8337 2.00684 11.4337 2.00684 8.00033C2.00684 4.56699 4.40684 2.16699 7.84017 2.16699C8.1135 2.16699 8.34017 2.39366 8.34017 2.66699C8.34017 2.94033 8.1135 3.16699 7.84017 3.16699C4.9935 3.16699 3.00684 5.15366 3.00684 8.00033C3.00684 10.847 4.9935 12.8337 7.84017 12.8337C8.1135 12.8337 8.34017 13.0603 8.34017 13.3337C8.34017 13.607 8.1135 13.8337 7.84017 13.8337Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoutIcon;
