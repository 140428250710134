import React from 'react';

const MediumIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2296 16.3062C17.2296 20.5482 13.8203 23.9871 9.61494 23.9871C5.40957 23.9871 2 20.5474 2 16.3062C2 12.0649 5.40931 8.625 9.61494 8.625C13.8206 8.625 17.2296 12.0641 17.2296 16.3062Z"
        fill="currentColor"
      />
      <path
        d="M25.5832 16.3054C25.5832 20.2984 23.8786 23.5367 21.7757 23.5367C19.6729 23.5367 17.9683 20.2984 17.9683 16.3054C17.9683 12.3125 19.6727 9.07422 21.7755 9.07422C23.8783 9.07422 25.5829 12.3114 25.5829 16.3054"
        fill="currentColor"
      />
      <path
        d="M29 16.3062C29 19.8829 28.4005 22.7842 27.6609 22.7842C26.9212 22.7842 26.322 19.8837 26.322 16.3062C26.322 12.7286 26.9215 9.82812 27.6609 9.82812C28.4003 9.82812 29 12.7284 29 16.3062Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MediumIcon;
