import React from 'react';

const InventoryIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M4.66668 7.16634H3.33334C1.72001 7.16634 0.833344 6.27967 0.833344 4.66634V3.33301C0.833344 1.71967 1.72001 0.833008 3.33334 0.833008H4.66668C6.28001 0.833008 7.16668 1.71967 7.16668 3.33301V4.66634C7.16668 6.27967 6.28001 7.16634 4.66668 7.16634ZM3.33334 1.83301C2.28001 1.83301 1.83334 2.27967 1.83334 3.33301V4.66634C1.83334 5.71967 2.28001 6.16634 3.33334 6.16634H4.66668C5.72001 6.16634 6.16668 5.71967 6.16668 4.66634V3.33301C6.16668 2.27967 5.72001 1.83301 4.66668 1.83301H3.33334Z"
        fill="currentColor"
      />
      <path
        d="M12.6667 7.16634H11.3333C9.72001 7.16634 8.83334 6.27967 8.83334 4.66634V3.33301C8.83334 1.71967 9.72001 0.833008 11.3333 0.833008H12.6667C14.28 0.833008 15.1667 1.71967 15.1667 3.33301V4.66634C15.1667 6.27967 14.28 7.16634 12.6667 7.16634ZM11.3333 1.83301C10.28 1.83301 9.83334 2.27967 9.83334 3.33301V4.66634C9.83334 5.71967 10.28 6.16634 11.3333 6.16634H12.6667C13.72 6.16634 14.1667 5.71967 14.1667 4.66634V3.33301C14.1667 2.27967 13.72 1.83301 12.6667 1.83301H11.3333Z"
        fill="currentColor"
      />
      <path
        d="M12.6667 15.1663H11.3333C9.72001 15.1663 8.83334 14.2797 8.83334 12.6663V11.333C8.83334 9.71967 9.72001 8.83301 11.3333 8.83301H12.6667C14.28 8.83301 15.1667 9.71967 15.1667 11.333V12.6663C15.1667 14.2797 14.28 15.1663 12.6667 15.1663ZM11.3333 9.83301C10.28 9.83301 9.83334 10.2797 9.83334 11.333V12.6663C9.83334 13.7197 10.28 14.1663 11.3333 14.1663H12.6667C13.72 14.1663 14.1667 13.7197 14.1667 12.6663V11.333C14.1667 10.2797 13.72 9.83301 12.6667 9.83301H11.3333Z"
        fill="currentColor"
      />
      <path
        d="M4.66668 15.1663H3.33334C1.72001 15.1663 0.833344 14.2797 0.833344 12.6663V11.333C0.833344 9.71967 1.72001 8.83301 3.33334 8.83301H4.66668C6.28001 8.83301 7.16668 9.71967 7.16668 11.333V12.6663C7.16668 14.2797 6.28001 15.1663 4.66668 15.1663ZM3.33334 9.83301C2.28001 9.83301 1.83334 10.2797 1.83334 11.333V12.6663C1.83334 13.7197 2.28001 14.1663 3.33334 14.1663H4.66668C5.72001 14.1663 6.16668 13.7197 6.16668 12.6663V11.333C6.16668 10.2797 5.72001 9.83301 4.66668 9.83301H3.33334Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InventoryIcon;
